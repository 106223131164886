import $ from 'jquery';

require("../components/card-similar");

/*
 * Refer a member form handling - done via ajax to support
 * Django form in a modal without a page refresh
 */

const referAMemberFormId = "#refer-a-member-form";

$("#refer-to-colleague").on("submit", referAMemberFormId, e => {
  e.preventDefault();

  let $referAMemberForm = $(referAMemberFormId);

  $.post($referAMemberForm.attr("action"), $referAMemberForm.serialize())
    .done(() => {
      location.reload();
    })
    .fail(r => {
      if (r.status === 400 && r.responseText) {
        $referAMemberForm.replaceWith(r.responseText);
      }
    });
});
