import $ from 'jquery';

const $seeMoreButtons = $("[data-see-more]");

function toggleCardExpertiseDetail(e) {
  e.preventDefault();
  let $card = $(e.target).closest("[data-card]");
  $card.find("[data-expertise-item]").toggle();
  $card.find("[data-see-more]").toggle();
}

$seeMoreButtons.click(toggleCardExpertiseDetail);
